const React = require('react');
const isMap = require('lodash/isMap');
const { Icon } = require('@vpp-frontend-components/icon');

const IconFactory = () => (icon, propsOrclassName) => {
  if (!icon) {
    return null;
  }
  let className;
  let props = {};
  if (typeof propsOrclassName === 'string') {
    className = propsOrclassName;
  } else if (isMap(propsOrclassName)) {
    className = propsOrclassName?.className;
    props = propsOrclassName;
  }

  if (React.isValidElement(icon)) {
    return <Icon element={icon} className={className} />;
  }

  const { id, color, description } = typeof icon === 'string' ? { id: icon } : icon;
  return <Icon id={id} className={className} description={description} color={color} {...props} />;
};

module.exports = IconFactory;
