// TAGS:

// Generic tags
const GENERIC_TAGS = ['device_type', 'referer_app', 'reason', 'is_disabled'];

// Iniciativa 1pay4all / Sequencer
const VPP_SEQUENCER_TAGS = [
  'button_type',
  'snackbar_type',
  'main_action_target',
  'main_action_fallback',
  'main_action_empty',
  'response_target',
  'response_fallback',
  'response_empty',
  'response_case',
  'end_case',
  'flow_redirect',
];

// Espacio para tags de otras iniciativas...

const ADD_TO_CART_TAGS = ['flow_type'];

// TAGS VALUES:

// Iniciativa 1pay4all / Sequencer
const REASON_TAG_VALUES = {
  NO_MAIN_ACTION_TARGET: 'no_main_action_target',
  CATCH_SEQUENCER: 'catch_sequencer',
  UNKNOW_CASE: 'unknow_case',
  NO_NAVIGATE_TARGET: 'no_navigate_target',
  NO_WEBVIEW_PRELOADED: 'no_webview_preloaded',
  NO_BOTTOMSHEET_TARGET: 'no_bottomsheet_target',
  INVALID_REDIRECT_URL: 'invalid_redirect_url',
  TIMEOUT_WEBVIEW_MESSAGE: 'timeout_webview_message',
  TIMEOUT_INITIAL_LOAD: 'timeout_initial_load',
};

const BUTTON_TYPES_TAG_VALUES = {
  BUY_NOW: 'buy_now',
  ADD_TO_CART: 'add_to_cart',
};

// Allowed tags
const ALLOWED_TAGS = [...ADD_TO_CART_TAGS, ...GENERIC_TAGS, ...VPP_SEQUENCER_TAGS]; // agregar spread de otras iniciativas

module.exports = {
  ADD_TO_CART_TAGS,
  ALLOWED_TAGS,
  GENERIC_TAGS,
  VPP_SEQUENCER_TAGS,
  REASON_TAG_VALUES,
  BUTTON_TYPES_TAG_VALUES,
};
