const React = require('react');

const IconFactory = require('../../../icons/factory-builder')();
const ActionRow = require('../../../action-row');

const namespace = 'ui-pdp-action';

const buildActionRow = (label, url, icon, onClickHandler, style_blocked) => {
  const figure = IconFactory(icon);
  return (
    <ActionRow
      className={`${namespace}__action`}
      label={label.text}
      url={url}
      style_blocked={style_blocked}
      modifier="secondary"
      figure={figure}
      onClick={onClickHandler}
    />
  );
};

module.exports = buildActionRow;
