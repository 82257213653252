const React = require('react');
const { useEffect } = require('react');
const { bool, string, shape, func } = require('prop-types');
const classNames = require('classnames');
const { VisuallyHidden } = require('@andes/common');
const { Button } = require('@andes/button');
const IconFactory = require('../../../icons/factory-builder')();
const { ACTION_TYPES } = require('../utils/action-types');

const buildActionRow = require('./build-action-row');
const {
  VPP_SEQUENCER_DATADOG_KEY_PREFIX,
  VPP_SEQUENCER_DATADOG_KEY_CASES,
} = require('../../../../services/frontend-statsd/config/allowed-keys');
const { BUTTON_TYPES_TAG_VALUES } = require('../../../../services/frontend-statsd/config/allowed-tags');

const namespace = 'ui-pdp-action';

const BuildButtonAction = props => {
  const {
    className,
    type,
    disabled,
    disabled_text,
    style_blocked,
    onClickHandler,
    label,
    size,
    hierarchy,
    icon,
    hasTopSeparator,
    actionType,
    fullWidth,
    buttonProps,
    isLoading,
    saveFrontendStatsd,
    fallbackUrl,
    target,
  } = props;

  const defaultProps = {
    ...buttonProps,
    className: classNames('andes-spinner__icon-base', className, `${namespace}--${type}`, {
      [`${namespace}-${type}--disabled`]: disabled === true,
    }),
    hierarchy,
    disabled: style_blocked,
    size,
    fullWidth,
  };

  useEffect(() => {
    if (actionType === ACTION_TYPES.SEQUENCER && saveFrontendStatsd) {
      const dataStatsd = {
        key: `${VPP_SEQUENCER_DATADOG_KEY_PREFIX}.${VPP_SEQUENCER_DATADOG_KEY_CASES.VPP_LOAD}`,
        tags: {
          is_disabled: Boolean(props?.disabled),
          button_type:
            props?.type === 'primary' ? BUTTON_TYPES_TAG_VALUES.BUY_NOW : BUTTON_TYPES_TAG_VALUES.ADD_TO_CART,
          main_action_target: Boolean(target),
          main_action_fallback: Boolean(fallbackUrl),
          main_action_empty: !target && !fallbackUrl,
        },
        info: (!target || !fallbackUrl) && { props },
      };

      saveFrontendStatsd(dataStatsd);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target, actionType]);

  if (hierarchy === 'actionRow') {
    return buildActionRow(label, defaultProps.href, icon, onClickHandler);
  }

  // TODO: FIX ME ! Hacemos esto (esta mal) porque el backend no nos manda la prop "hasTopSeparator"
  const showTopSeparator = hasTopSeparator || actionType === ACTION_TYPES.ASK_QUESTIONS_AI;

  return (
    <>
      {showTopSeparator && <div className="ui-pdp-action-separator" />}
      <Button data-testid="button" onClick={onClickHandler} loading={!disabled && isLoading} {...defaultProps}>
        {icon && IconFactory(icon.id, `ui-pdp-action-icon--${icon.color}`)}
        {label && label.text}
        {disabled && disabled_text && <VisuallyHidden>{`. ${disabled_text}`}</VisuallyHidden>}
      </Button>
    </>
  );
};

BuildButtonAction.propTypes = {
  className: string,
  type: string.isRequired,
  disabled: bool,
  disabled_text: string,
  onClickHandler: func,
  label: shape({}),
  size: string,
  hierarchy: string,
  icon: shape({}),
  style_blocked: bool,
  hasTopSeparator: bool,
  buttonProps: shape({}),
  isLoading: bool,
  actionType: string,
  fullWidth: bool,
  saveFrontendStatsd: func,
  fallbackUrl: string,
  target: string,
};

BuildButtonAction.defaultProps = {
  className: null,
  disabled: false,
  disabled_text: null,
  onClickHandler: null,
  label: null,
  size: null,
  hierarchy: null,
  icon: null,
  style_blocked: false,
  hasTopSeparator: false,
  buttonProps: {},
  isLoading: false,
  saveFrontendStatsd: null,
  fallbackUrl: null,
  target: null,
};

module.exports = BuildButtonAction;
